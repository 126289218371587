<template>
    <div class="container">

        <div class="about" id="gallery">
            <h1>Gallery</h1>
            <div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button v-for="(image, index) in images" :key="index" type="button"
                        :data-bs-target="'#carouselExampleDark'" :data-bs-slide-to="index"
                        :class="{ active: index === 0 }" :aria-label="'Slide ' + (index + 1)"></button>
                </div>
                <div class="carousel-inner">
                    <div v-for="(image, index) in images" :class="['carousel-item', { active: index === 0 }]"
                        :key="image.id" data-bs-interval="10000">
                        <img :src="image.src" class="d-block w-100" :alt="image.alt">
                        <div class="carousel-caption d-none d-md-block">
                            <h5>{{ image.title }}</h5>
                            <p>{{ image.description }}</p>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OtherView",
    data() {
        return {
            images: [
                //{ id: 1, src: require('../assets/gallery/auge.jpeg')},
                { id: 1, src: require('../assets/gallery/nova.svg') },
                { id: 2, src: require('../assets/gallery/weird_stairs.jpeg') },
                { id: 3, src: require('../assets/gallery/simple.svg') },
                { id: 4, src: require('../assets/gallery/squares.jpeg') },
                { id: 5, src: require('../assets/gallery/edge_of_infinity.jpeg') },
                { id: 6, src: require('../assets/gallery/mandala.svg') },
                { id: 7, src: require('../assets/gallery/auge.jpeg') },
                { id: 8, src: require('../assets/gallery/space.jpeg') },
                { id: 9, src: require('../assets/gallery/faces.jpeg') },
                { id: 10, src: require('../assets/gallery/flower.jpeg') },
                { id: 11, src: require('../assets/gallery/mandala2.jpeg') },
                { id: 12, src: require('../assets/gallery/tear.jpeg') },
                { id: 13, src: require('../assets/gallery/tounge.jpeg') },
                // { id: 14, src: require('../assets/gallery/')},
                // { id: 15, src: require('../assets/gallery/')},
                // { id: 16, src: require('../assets/gallery/')},
                // { id: 17, src: require('../assets/gallery/')},
                // { id: 18, src: require('../assets/gallery/')},
                // { id: 19, src: require('../assets/gallery/')},
                // { id: 20, src: require('../assets/gallery/')},
                // { id: 21, src: require('../assets/gallery/')},
                // { id: 22, src: require('../assets/gallery/')},
                // { id: 23, src: require('../assets/gallery/')},
                // Add more images as needed
            ]
        }
    },
}
</script>

<style scoped>
.carousel-item img {
    height: 85vh;
    /* Adjust 50px to the height of your navbar */
    width: auto;
    object-fit: contain;
}

.about {

    width: 100%;
    height: 95%;
    text-align: center;
}

li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.container {
    display: grid;
    width: 100vw;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1em;
}
</style>
