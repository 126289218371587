<template>
  <div class="container">
    <div class="about" id="contact">
      <div class="row align-items-stretch">
      <h1>Contact</h1>
      <div class="col thresh">
      <ul>
        <li>
          <a href="mailto:avalleo@proton.me">
            <div class="logo-container">
              <img src="@/assets/logos/Proton.svg" alt="ProtonMail">
            </div>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/leonhard-abt-736a05249/">
            <div class="logo-container">
              <img src="@/assets/logos/LinkedIn_Logo.svg" alt="LinkedIn">
            </div>
          </a>
        </li>
        <li>
          <iframe src=" https://cloud.leo248.com/apps/appointments/embed/ZCzwr9AvBb9V8NN8/form " width="100%" height="700em" frameborder="0">Loading…</iframe>
        </li>
      </ul>
      </div>
    </div>
    </div>
    <div class="about" id="links">
          <div class="row align-items-stretch">

      <h1>Links</h1>
      <div class="col thresh">
      <ul>
        <li>
          <a href="https://www.github.com/leo248">
            <div class="logo-container">
              <img src="@/assets/logos/Github_logo_svg.svg" alt="Current GitHub Logo">
              <img src="@/assets/logos/GitHub_logo_2013.svg" alt="Old GitHub Logo">
            </div>
          </a>
        </li>
      </ul>
    </div>
    </div>
    </div>
  </div>
</template>



<script>
export default {
  name: "AboutView",
}
</script>
<style scoped>

.about h1 {
  font-size: 3em;
}
.about {
  width: 100%;
  height: 95%;
  text-align: center;
}

li {
  list-style-type: none;
  margin: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  height: 3em; /* Uniform height for all logos */
  width: auto; /* Width adjusts automatically */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents image overflow */
}

.logo-container img {
  height: 100%; /* Ensures image fills the container's height */
  width: auto; /* Maintains image aspect ratio */
  object-fit: contain; /* Ensures the image is scaled correctly */
}

.container {
  display: grid;
  width: 100vw;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "About"
    "Links";
}
.row {
  margin-top: 3em;
}
.col {
  flex: 1;
  padding: 1em;
  justify-content: start;
}
.thresh {
  background-color: #ededed81;
  border-radius: 1%;

}
</style>
