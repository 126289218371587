// ./src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MainLayout from '@/layouts/MainLayout.vue';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import MathView from '@/views/MathView.vue';
import ProgView from '@/views/ProgView.vue';
import OtherView from '@/views/OtherView.vue';
import { nextTick } from 'vue';

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      { path: '', name: 'Home', component: HomeView, meta: { title: 'Leo248 | Home' }},
      { path: 'about', name: 'About', component: AboutView, meta: { title: 'Leo248 | About' } },
      { path: 'math', name: 'Math', component: MathView, meta: { title: 'Leo248 | Math' } },
      { path: 'programming', name: 'Prog', component: ProgView, meta: { title: 'Leo248 | Programming' } },
      { path: 'other', name: 'Other', component: OtherView, meta: { title: 'Leo248 | Other' } },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//eslint-disable-next-line
router.afterEach((to, from) => {
  // Check if we're still on the same route and only the hash changed
  if (to.path === from.path && to.hash) {
    scrollToHash(to.hash);
  } else if (to.hash) {
    // Ensure the page has rendered before scrolling
    nextTick(() => {
      scrollToHash(to.hash);
    });
  }
});

function scrollToHash(hash) {
  const element = document.getElementById(hash.substring(1));
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

export default router
