<!-- KaTexComponent.vue -->
<template>
    <span v-html="renderedKatex"></span>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import 'katex/dist/katex.min.css';
import katex from 'katex';

export default defineComponent({
    name: 'KaTexComponent',
    props: {
        equation: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            default: () => ({ throwOnError: false }),
        },
    },
    setup(props) {
        const renderedKatex = ref("");

        watch(
            () => props.equation,
            (newValue) => {
                renderedKatex.value = katex.renderToString(newValue, props.options);
            },
            { immediate: true }
        );

        return { renderedKatex };
    },
});
</script>

