<template>
  <div class="container">
    <div class="about" id="pythresh">
      <h1>Ideal (t, n)-Threshold</h1>
      <div class="row align-items-stretch">
        <div class="col thresh">
          <h2> Abstract </h2>
          <p>To distribute a secret among a group of people, secret sharing schemes are often used. Here, the secret is
            divided among the participants, so that a specific subset of participants is required to decrypt the secret.
          </p>
          <ul>
            <li><strong>Reconstruction:</strong> A sufficient number of participants (according to the scheme's
              requirements) must be able to fully reconstruct the secret.</li>
            <li><strong>Security:</strong> An insufficient number of participants should not be able to gain any useful
              information about the secret.</li>
            <li><strong>Robustness:</strong> The scheme should be resistant to potential fraud and compromise by
              participants.</li>
          </ul>

          <h2> Introduction</h2>
          <p>Projective spaces can be used to construct various types of secret sharing schemes, such as t-threshold
            schemes and (s, t)-multilevel schemes.</p>
          <p>Let
            <KaTexComponent :equation="'G'" /> be a line in
            <KaTexComponent :equation="'PG(t, q)'" />, whose
            <KaTexComponent :equation="'q + 1'" /> points are the possible secrets.
            Furthermore, for each point
            <KaTexComponent :equation="'p'" /> in
            <KaTexComponent :equation="'G'" />, let there be a hyperplane
            <KaTexComponent :equation="'H_p'" /> that
            does not contain
            <KaTexComponent :equation="'G'" /> and intersects at
            <KaTexComponent :equation="'p'" />, and a
            <KaTexComponent :equation="'(q + 1) \\text{-arc } C_p'" />
            in
            <KaTexComponent :equation="'H_p \\sim PG(t - 1, q)'" /> containing
            <KaTexComponent :equation="'p'" />, whose points then form
            the corresponding partial secrets. This defines a <em>t</em>-threshold scheme, where the knowledge of
            <KaTexComponent :equation="'t-1'" /> partial secrets only allows choosing the correct secret with a
            probability of
            <KaTexComponent :equation="'1 / (q +1)'" />.
          </p>
          <p>Jungnickel, D. (2003). Einführung in die Kombinatorik. de Gruyter S.202</p>
          <p>Explore my first Python implementation of this concept on GitHub:
            <a href="https://www.github.com/leo248/pythresh" target="_blank" rel="noopener noreferrer">Check it out on
              GitHub</a>
          </p>

        </div>
        <div class="col thresh">
          <div class="images">
            <div class="image-container">
              <img src="../assets/math_imgs/ok.svg" alt="Diagram of Fanospace (PG(3,2))">
              <p class="caption">The Fanospace <KaTexComponent :equation="'(PG(3,2))'" /></p>
            </div>
            <div class="image-container">
              <img src="../assets/math_imgs/Figure_1.svg" alt="Diagram of a secret line intersected by a hyperplane">
              <p class="caption">A secret line <KaTexComponent :equation="'(A, J, H)'" /> that is intersected by a hyperplane.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KaTexComponent from "../components/Katex.vue";
export default {
  name: "MathView",
  components: {
    KaTexComponent,
  }

}
</script>

<style scoped>
.about {
  margin-top: 5%;
  height: 95%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.thresh {
  background-color: #ededed81;
  border-radius: 1%;

}



.content,
.images {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.images {
  align-items: center;
}

.image-container {
  margin-top: 5px;
}

.caption {
  text-align: center;
  font-style: italic;
  margin-top: 5px;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100%;
}

.col {
  flex: 1;
  padding: 1em;
  justify-content: start;
}

.row {
  margin-top: 3em;
}
</style>
