<template>
  <div id="app">

      <vue-particles
          id="tsparticles"
          :options="{
                  background: {
                      color: {
                      }
                  },
                  fpsLimit: 120,
                  interactivity: {
                      events: {
                          onClick: {
                              enable: true,
                              mode: 'push'
                          },
                          onHover: {
                              enable: true,
                              mode: 'repulse'
                          },
                      },
                      modes: {
                          bubble: {
                              distance: 400,
                              duration: 2,
                              opacity: 0.8,
                              size: 40
                          },
                          push: {
                              quantity: 4
                          },
                          repulse: {
                              distance: 200,
                              duration: 0.4
                          }
                      }
                  },
                  particles: {
                      color: {
                          value: '#ffffff'
                      },
                      links: {
                          color: '#ffffff',
                          distance: 150,
                          enable: true,
                          opacity: 0.5,
                          width: 1
                      },
                      move: {
                          direction: 'none',
                          enable: true,
                          outModes: 'bounce',
                          random: false,
                          speed: 1,
                          straight: false
                      },
                      number: {
                          density: {
                              enable: true,
                          },
                          value: 40
                      },
                      opacity: {
                          value: 0.5
                      },
                      shape: {
                          type: 'circle'
                      },
                      size: {
                          value: { min: 1, max: 5 }
                      }
                  },
                  detectRetina: true
              }"
      />
  </div>
</template>
<script>

export default {
  name: 'GlobalBackground'
}
</script>

<style>
.svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.node {
  fill: steelblue;
  stroke: #fff;
}

.link {
  stroke: #000000;
  stroke-width: 1.5px;
}

.highlighted {
  stroke: rgb(248, 27, 27);
  stroke-width: 4px;
}

body {
  background: linear-gradient(119deg,
      rgba(18, 36, 240, 0.314) 0%,
      rgba(255, 110, 13, 0.314) 100%);
}
</style>