<template>
    <div class="main-layout">
        <NavBar></NavBar>
        <div class="main-background">
            <GlobalBackground></GlobalBackground>
        </div>
        <div class="main-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import GlobalBackground from '@/components/GlobalBackground.vue';
export default {
    components: {
        NavBar,
        GlobalBackground
    },
}
</script>

<style>

.main-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}
.main-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
}
.main-content {
    position: relative;
    flex-grow: 1;
    overflow: auto;
}

.GlobalBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; 
}
</style>
