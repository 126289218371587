<!-- src/views/HomeView.vue--->
<template>
    <div class="home-container">
      <div class="Math" @click="navigateTo('math')">
        <div class="head-content">
          <h1>Math</h1>
          <p>The study of numbers, shapes, and related structures.</p>
        </div>
      </div>
      <div class="Programming" @click="navigateTo('programming')">
        <div class="head-content">
          <h1>Programming</h1>
          <p>The process of creating a set of instructions.</p>
        </div>
      </div>
      <div class="Other" @click="navigateTo('other')">
        <div class="head-content">
          <h1>Other</h1>
          <p>Topics include science, history, art, and more.</p>
        </div>
      </div>
      <div class="About" @click="navigateTo('about')">
        <div class="head-content">
          <h1>About</h1>
          <p>Contact, Links, Misc</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "HomeView",
  methods: {
    navigateTo(section) {
      //navigate to the /xxx via vue router
      this.$router.push({ path: section });

    
    },
  },
}
</script>

<style scoped>


</style>
