<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>


<script>

export default {
  name: 'App',
  components: {

  }
};


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
h2 {
  margin-top: 1em;

}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  
}
</style>
