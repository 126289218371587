<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand bg-dark" href="/">Home</a>
        <button class="navbar-toggler bg-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon "></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav navbar-dark">
            <li class="nav-item bg-dark" v-for="item in navItems" :key="item.id">
              <a class="nav-link bg-dark" :href="item.href" v-if="!item.children">{{ item.name }}</a>
              <div v-else class="nav-item dropdown bg-dark">
                <a class="nav-link dropdown-toggle bg-dark" :href="item.href" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ item.name }}
                </a>
                <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdownMenuLink">
                  <li v-for="child in item.children" :key="child.name">
                    <a class="dropdown-item bg-dark" :href="child.href">{{ child.name }}</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    name: "NavBar",
    data() {
      return {
        navItems: [
          { 
            id: 0, 
            name: 'Math', 
            href: '/math', 
            children: [
              { name: 'Ideal (t, n)-Threshold', href: '/math#pythresh' },
            ],
          },
          {
            id: 2,
            name: 'Programming',
            href: '/programming',
            children: [
              { name: 'Arcade Game', href: '/programming#arcadegame' },
            ],
          },
          {
            id: 3,
            name: 'Other',
            href: '#',
            children: [
              { name: 'Gallery', href: '/other#gallery' },
            ],
          },
          {
            id: 4,
            name: 'About',
            href: '/about',
            children: [
              { name: 'Contact', href: '/about#contact' },
              { name: 'Links', href: '/about#links' },
            ],
          }
        ],
      };
    },
  }
  </script>
  
<style scoped>
.navbar li {
    font-size: 1.5rem;
    list-style-type: none; /* Targets all li within navbar to remove bullets */
    padding-left: 0; /* Removes padding which might be causing misalignment */
}

.navbar-nav .dropdown-menu {
    padding: 0; /* Specific padding removal for dropdown menus */
}

.navbar-brand { 
    font-size: 1.5rem;
}





.navbar {
    position: relative; /* Ensure position is not 'static' */
    z-index: 1000; /* Adjust value as needed, higher values are on top */
}


</style>

  