<!-- src/views/ProgView.vue -->
<template>
  <div class="container">
    <div class="about" id="arcadegame">
      <h1>Arcade Game - Python</h1>
      <div class="row align-items-stretch">
        <div class="col thresh">
          <h2>Axial Movement</h2>
          <p>The simple linear movement of a 3D object can be explained as follows:</p>
          <p>
            First, we define:
            <KaTexComponent :equation="'v'" /> as the velocity vector of the player:
            <KaTexComponent :equation="'v \\in \\mathbb{R}^3'" />,
            <KaTexComponent :equation="'\\Delta t'" /> as the time increment and
            <KaTexComponent
              :equation="'P_{player} = \\{x \\mid x \\in \\text{Object}, x \\text{ relative to } O_{player}\\}'" />,
            the set of all points representing the surface of an object.
          </p>
          <p>
            This gives the new position of the player, after moving for a time interval
            <KaTexComponent :equation="'\\Delta t'" />:
          </p>
          <p>
            <KaTexComponent :equation="'P_{player_{new}} = P_{player_{current}} + v \\cdot \\Delta t'" />
          </p>


          <h2>Axial Rotation</h2>
          <p>We consider two coordinate systems: the world's coordinate system and the one described by the player's
            edges.
            Since all the game figures are moving on a plane, the rotation that the player is doing to change it's
            orientation can be described as a simple rotation around player's own z-axis. We define: </p>
          <li>
            <ul>
              <KaTexComponent :equation="'\\varphi'" /> as the angle of rotation,
            </ul>
            <ul>
              <KaTexComponent :equation="'P_{player}'" /> as the rotation matrix of the player,
            </ul>
            <ul>
              <KaTexComponent
                :equation="'P_{world} = \\{x \\mid x \\in \\text{Object}, x \\text{ relative to } O_{world}\\}'" /> as
              the set of all point representing the surface of an object
            </ul>
          </li>
          <p>Therefore, the homogeneous matrix looks like this:</p>
          <KaTexComponent
            :equation="'{}^{world}_{player}T = \\left[ \\begin{array}{c|c} Rot_z(\\varphi) & O_{player} \\\\ \\hline 0 & 1 \\end{array} \\right]'" />
          <p>If we rotate the player around its own z-axis and want to have the new coordinates in reference to the
            world coordinates, we compute:</p>
          <p>
            <KaTexComponent :equation="'P_{world} = {}^{world}_{player}T \\cdot P_{player}.'" />
          </p>



          <h2>Camera Orientation</h2>
          <p>To adjust the player's orientation and align the camera, we use quaternions. The following definitions help
            explain how the orientation is determined:</p>
          <p>
            <KaTexComponent
              :equation="'q = (\\alpha_1, \\alpha_2, \\alpha_3, \\alpha_4) = \\alpha_1 \\cdot 1 + \\alpha_2 \\cdot i + \\alpha_3 \\cdot j + \\alpha_4 \\cdot k.'" />
          </p>
          <p>
            <KaTexComponent
              :equation="'q \\cdot p = (\\alpha_1\\beta_1 - \\alpha_2\\beta_2 - \\alpha_3\\beta_3 - \\alpha_4\\beta_4) + (\\alpha_1\\beta_2 + \\alpha_2\\beta_1 + \\alpha_3\\beta_4 - \\alpha_4\\beta_3) \\cdot i + (\\alpha_1\\beta_3 - \\alpha_2\\beta_4 + \\alpha_3\\beta_1 + \\alpha_4\\beta_2) \\cdot j + (\\alpha_1\\beta_4 + \\alpha_2\\beta_3 - \\alpha_3\\beta_2 + \\alpha_4\\beta_1) \\cdot k.'" />
          </p>
          <p>Quaternions are Hamilton's mathematical objects. Their norm is defined by:</p>
          <p>
            <KaTexComponent
              :equation="'|q| = \\sqrt{q \\cdot \\overline{q}} = \\sqrt{\\alpha_1^2 + \\alpha_2^2 + \\alpha_3^2 + \\alpha_4^2}.'" />
          </p>
          <p>Quaternions with a norm of 1 are called unit quaternions, denoted as
            <KaTexComponent :equation="'\\mathbb{H}_1 \\text{ or } \\mathbb{S}^3'" />.
            They can be represented as:
          </p>
          <p>
            <KaTexComponent :equation="'q = [\\cos(\\varphi), \\sin(\\varphi) v]'" />
          </p>
          <p>Unit quaternions can be used for rotations. When rotating with a quaternion, the result is a rotation
            around
            <KaTexComponent :equation="'v \\text{ by }  2 \\cdot \\varphi'" />. To express the rotation as a matrix, we use
            the mapping:
          </p>
          <p>
            <KaTexComponent :equation="'f:(\\mathbb{H}_1, \\cdot) \\rightarrow (SO(3)(\\mathbb{R}), \\cdot)'" />
          </p>
          <p>The corresponding rotation matrix is:</p>
          <KaTexComponent
            :equation="'\\left[ \\begin{array}{ccc} q_1^2 + q_2^2 - q_3^2 - q_4^2 & 2(-q_1q_4 + q_2q_3) & 2(q_1q_3 + q_2q_4) \\\\ 2(q_1q_4 + q_2q_3) & q_1^2 - q_2^2 + q_3^2 - q_4^2 & 2(-q_1q_2 + q_3q_4) \\\\ 2(-q_1q_3 + q_2q_4) & 2(q_1q_2 + q_3q_4) & q_1^2 - q_2^2 - q_3^2 + q_4^2 \\end{array} \\right]'" />
        </div>

        <div class="col thresh">
          <div class="images">
            <div class="image-container">
              <video class="responsive-media" autoplay muted loop>
                <source src="../assets/videos/bob.mp4" type="video/mp4">
              </video>
            </div>
            <div class="image-container">
              <img class="responsive-media" src="../assets/prog_imgs/uml.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import KaTexComponent from "../components/Katex.vue";
export default {
  name: "ProgView",
  components: {
    KaTexComponent,
  }
}

</script>


<style scoped>
.about {
  margin-top: 5%;
  height: 95%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.thresh {
  background-color: #ededed81;
  border-radius: 1%;

}



.content,
.images {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.image-container {
  margin-top: 5px;
  max-width: 430px;
  width: 100%;
  box-sizing: border-box;
}

.caption {
  text-align: center;
  font-style: italic;
  margin-top: 5px;
}

li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100%;
}

.col {
  flex: 1;
  padding: 1em;
  justify-content: start;
}

.row {
  margin-top: 3em;
}

.responsive-media {
  max-width: 100%;
  /* Makes video and images responsive */
  height: auto;
  /* Maintains aspect ratio */
}
</style>